.gap-menu {
  gap: 10px;
}
.page-header {
  top: 0;
  z-index: 1000;
  .header-content {
    .header__content {
      position: fixed;
      width: 100%;
      max-width: 1960px;
      z-index: 10;
      padding: 20px 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--color-bg-main);
      border-bottom: 1px solid #d1d1d1;

      @media screen and (max-width: 1280px) {
        padding: 20px 20px;
      }
      .header-mobile {
        display: flex;
        gap: 20px;
      }
      .logo {
        cursor: pointer;
        width: 300px;
        img {
          width: 140px;
          height: 45px;
          @media screen and (max-width: 812px) {
            width: 100px;
            height: 32px;
          }
        }
        @media screen and (max-width: 1279px) {
          width: 220px;
        }
      }
      .nav-links {
        .links {
          display: flex;
          align-items: center;
          flex: wrap !important;
          gap: 45px;

          .link-item {
            font-size: 16px;
            font-weight: 500;
            color: #aaaaaa;
            text-decoration: none;
            &:hover {
              color: var(--color-text-main);
            }
          }
        }
      }
      .nav-btn {
        font-size: 16px;
        font-weight: 700;
        .whitepaper-btn {
          width: 140px;
          height: 40px;
          color: #ffffff;
          border: 1px solid #107def;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .launch-app-btn {
          width: 160px;
          height: 40px;
          color: #107def;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          cursor: pointer;
          white-space: nowrap;
        }

        @media screen and (max-width: 1279px) {
          font-size: 14px;
          .whitepaper-btn {
            width: 100px;
          }
          .launch-app-btn {
            width: 160px;
          }
        }
      }
    }
  }
}
.drawer-menu-mobile {
  margin-top: 73px;
  .ant-drawer-content-wrapper {
    width: 100% !important;
    box-shadow: none !important;
    -webkit-box-shadow: none;
  }
  .ant-drawer-content {
    background-color: var(--color-bg-main);
  }
  .ant-drawer-body {
    .nav-link-mobile {
      display: block;
      text-align: center;
    }
    .drawer-body-item {
      margin-bottom: 20px;
      .link-item {
        font-size: 20px;
        font-weight: 500;
        color: #aaaaaa;
        text-decoration: none;
      }
    }
    .nav-btn-mobile {
      font-size: 16px;
      font-weight: 700;
      .whitepaper-btn {
        width: 140px;
        height: 40px;
        color: #ffffff;
        border: 1px solid #107def;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto;
      }
      .launch-app-btn {
        width: 160px;
        height: 40px;
        color: #107def;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
  }
}
