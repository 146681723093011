
.wrap-terms-container {
  padding: 150px 120px 50px 120px;
  color: var(--color-text-main);
  font-size: 16px;
  background: transparent;
  @media screen and (max-width: 812px) {
    padding: 100px 20px 150px 20px;
  }
  .apeframe {
    width: 100%;
    height: 80vh;
  }

  .loading-spinner {
    height: fit-content;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 0;
  }
}